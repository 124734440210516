import { createAsyncThunk, createListenerMiddleware, createSlice, PayloadAction, TypedStartListening } from '@reduxjs/toolkit';
import { definitions } from '@/apitypes';
import * as actions from '@/store/actions';
import { CalculatePriceQuery, GetRebateCodeBySignQuery, Quantity, Time, TotalPriceInformationResponse } from '@/types';
import { AxiosResponse } from 'axios';
import { apiClient, throwSubmissionError } from '@/utils';
import { clearSubmitErrors } from 'redux-form';
import { ApplicationDispatch, ApplicationState } from '@/store';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';

interface State {
    entity: { [key in string] : TotalPriceInformationResponse & { Quantity?: number, PriceId?: number} } | null;
    loading: boolean;
    error: any;
}
const initialState: State = {
    entity: null,
    loading: false,
    error: null,
};

export const calculatePriceListener = createListenerMiddleware();
export type CalculatePriceStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;
const startCalculatePriceListener = calculatePriceListener.startListening as CalculatePriceStartListening;

// Thunks

export const applyRebateCode = createAsyncThunk(
    '/services/calculatePrice/APPLY_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: Quantity[],
        customerEmail?: string,
        serviceId: number
    }, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;
            const translate = getTranslate(state.localize);
            const companyId = state.company.data?.Id;
            const from = interval.From.toString();
            const alreadyAppliedCode =
                state.calculatePrice.entity &&
                Object.values(state.calculatePrice.entity).some(p => 
                    p.AppliedCodes.map(
                        (a) => a.RebateCodeSign
                    ).includes(rebateCodeSign)
                );
            
            if(alreadyAppliedCode) {
                return thunkApi.rejectWithValue(throwSubmissionError({
                    _error: translate('newBookingForm.codeAlreadyApplied')
                }));
            }

            const getbysignResponse: AxiosResponse = await apiClient.get('/rebatecodes/getbysign', {
                params: {
                    RebateCodeSign: rebateCodeSign,
                    Date: from,
                    CustomerEmail: customerEmail,
                    ServiceId: serviceId,
                    CompanyId: companyId,
                },
            });

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: [getbysignResponse.data.Id],
                CustomerEmail: customerEmail,
            });

            

            return thunkApi.fulfillWithValue({
                date: from,
                data: calculatePriceResponse.data as TotalPriceInformationResponse,
            });
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const removeRebateCode = createAsyncThunk(
    '/services/calculatePrice/REMOVE_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: Quantity[],
        customerEmail: string,
        serviceId: number
    }, thunkApi) => {
        try {
            const from = interval.From.toString();
            const state = thunkApi.getState() as ApplicationState;
            const rebateCodeIds = state.calculatePrice.entity && state.calculatePrice.entity[from]
                ? state.calculatePrice.entity[from].AppliedCodes
                : [];

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: rebateCodeIds.filter(code => code.RebateCodeSign === rebateCodeSign),
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue({ date: from, data: calculatePriceResponse.data as TotalPriceInformationResponse});
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const calculatePrice = createAsyncThunk(
    '/services/calculatePrice/CALCULATE_PRICE',
    async ({
        interval,
        quantities = [],
        customerEmail,
        serviceId,
        rebateCodeIds = []
    } : {
        interval: Time,
        quantities: Quantity[],
        customerEmail?: string,
        serviceId: number,
        rebateCodeIds?: number[]
    }, thunkApi) => {
        try {
            const from = interval.From.toString();

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                CustomerEmail: customerEmail,
                RebateCodeIds: rebateCodeIds
            } as CalculatePriceQuery);
            
            thunkApi.dispatch(clearSubmitErrors(`promoCodes.${from}`));

            return thunkApi.fulfillWithValue({
                date: from,
                data: calculatePriceResponse.data as TotalPriceInformationResponse,
            });
            
        } catch (error) {
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    })

// Slice

const calcualtePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(calculatePrice.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(calculatePrice.fulfilled, (state, action) => {
            const oldEntity = state.entity || {};
            state.entity = {
                ...oldEntity,
                [action.payload.date]: {
                    ...action.payload.data,
                    Quantity: action.meta.arg.quantities[0].Quantity,
                    PriceId: action.meta.arg.quantities[0].PriceId
                }
            }
            
            state.loading = false;
        });

        builder.addCase(calculatePrice.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        builder.addCase(applyRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(applyRebateCode.fulfilled, (state, { payload }) => {
            state.loading = false;
            const oldEntity = state.entity || {};
            state.entity = {
                ...oldEntity,
                [payload.date]: {
                    ...oldEntity[payload.date],
                    ...payload.data
                }
            }
        });

        builder.addCase(applyRebateCode.rejected, (state, action) => {
            state.loading = false;
        });


        builder.addCase(removeRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(removeRebateCode.fulfilled, (state, { payload }) => {
            state.loading = false;
            const oldEntity = state.entity || {};
            state.entity = {
                ...oldEntity,
                [payload.date]: {
                    ...oldEntity[payload.date],
                    ...payload.data
                }
            }
        });

        builder.addCase(removeRebateCode.rejected, (state, action) => {
            state.loading = false;
        });

    },
});

startCalculatePriceListener({
    matcher: calculatePrice.fulfilled.match,
    effect: async (action, api) => {
        const state = api.getState();
        
        if(!state.calculatePrice.loading && state.calculatePrice.entity) {
            api.dispatch({
                type: 'UPDATE_SERVICE_QUANTITIES',
                payload: []
            })
        }
    }
});

export default calcualtePriceSlice;
