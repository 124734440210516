import { AnyAction } from 'redux';
import { put, call, all, select } from 'redux-saga/effects';
import * as actions from '@/store/actions';
import { apiClient, throwSubmissionError } from '@/utils';
import { getTranslate } from 'react-localize-redux';
import { push } from 'connected-react-router';
import { confirmSaga } from './confirm';
import { Price, Service, TotalPriceInformationResponse } from '@/types';
import Notifications from 'react-notification-system-redux';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import qs from 'qs';
import { ApplicationState } from '@/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { definitions, operations } from '@/apitypes';


export function* services({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services', {
            params: {
                Active: true,
                IncludeBookingStatusOptions: true,
                ...payload,
            },
        });

        yield put(actions.FETCH_SERVICES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SERVICES.failure(error));
    }
}

export function* fetchServiceDurationTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services/durationtypes');

        yield put(actions.FETCH_SERVICE_DURATION_TYPES.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_SERVICE_DURATION_TYPES.failure(error));
    }
}

const transformFormData = (formData: any) => {
    let Duration = formData._Duration;
    let MinDuration = formData._MinDuration;
    let MaxDuration = formData._MaxDuration;
    let DurationInterval = formData._DurationInterval

    if (formData._DurationBaseUnit === 'days') {
        Duration = formData._Duration * 1440;
        MaxDuration = formData._MaxDuration * 1440;
        MinDuration = formData._MinDuration * 1440;
        DurationInterval = formData._DurationInterval * 1440;

    } else if (formData._DurationBaseUnit === 'hours') {
        MinDuration = formData._MinDuration * 60;
        MaxDuration = formData._MaxDuration * 60;
        Duration = formData._Duration * 60;
        DurationInterval = formData._DurationInterval * 60;
    }

    return {
        ...formData,
        Duration,
        // Send flexible hour fields only when duration type is not fixed
        ...(formData.DurationTypeId !== 1
            ? {
                  MinDuration,
                  MaxDuration,
                  DurationInterval,
              }
            : {}),
        ResourceTypes: formData.ResourceTypes.filter((resourceType: any) => resourceType.selected),
        Schedules:
            formData.Schedules.ScheduleType === 'DateSchedule'
                ? {
                      DateSchedules: formData.Schedules.DateSchedules.filter(
                          (schedule: any) => schedule.selected
                      ),
                  }
                : {
                      RecurringSchedules: formData.Schedules.RecurringSchedules.filter(
                          (schedule: any) => schedule.selected
                      ),
                  },
        Group: formData.newGroup || formData.Group,
        DurationTypeId: formData._FlexibleHours,
    };
};

export function* createService({ payload }: AnyAction) {
    const create = actions.CREATE_SERVICE;
    const location: Location = yield select((s: ApplicationState) => s.router.location);
    
    try {
        const response: AxiosResponse = yield apiClient.post(`/services`, transformFormData(payload));

        yield put(create.success(response.data));

        const query = qs.parse(location.search.slice(1));

        if (query.afterSuccess) {
            yield put(push(query.afterSuccess as string))
        }
    } catch (error) {
        yield put(create.failure(throwSubmissionError(error)));
    }
}

export function* editService({ payload }: AnyAction) {
    const edit = actions.EDIT_SERVICE;
    try {
        const response: AxiosResponse = yield apiClient.put(`/services/${payload.Id}`, transformFormData(payload));

        yield put(edit.success(response.data));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* editServicePriceViewType({ payload }: AnyAction) {
    const service: Service = yield select(s => s.services.data[0])
    const requestPayload = {
        ...service,
        ...payload
    };

    const edit = actions.EDIT_SERVICE_PRICEVIEW_TYPE;
    
    try {
        const response: AxiosResponse = yield apiClient.put(`/services/${service.Id}`, transformFormData(requestPayload));

        yield put(edit.success(response.data));
    } catch (error) {
        yield put(edit.failure(throwSubmissionError(error)));
    }
}

export function* deleteService({ payload }: AnyAction) {
    const confirmed: boolean = yield call(confirmSaga);
    if (confirmed) {
        try {
            const response: AxiosResponse = yield apiClient.delete(`/services/${payload.Id}`, payload);

            yield put(actions.DELETE_SERVICE.success(response.data));
            yield put(push('/services'));
        } catch (error: any) {
            if(error.response.status === 403) {
                yield put(Notifications.show(
                    {
                        title: error?.response?.data?.ResponseStatus?.Message,
                    },
                    'error'
                ));
            } else {
                yield put(actions.DELETE_SERVICE.failure(error));
            }
        }
    }
}

export function* copyService({ payload: { service, prices = [] } }: AnyAction) {
    try {
        const localizeState: RootState['localize'] = yield select((state) => state.localize);
        const translate = getTranslate(localizeState);
        const serviceResponse: AxiosResponse = yield apiClient.post(`/services`, {
            ...service,
            Id: undefined,
            Name: `[${translate('common.copy')}] ${service.Name}`,
        });

        const newServiceId = serviceResponse.data.Id;

        yield all(
            prices
                .filter((price: Price) => price.ServiceId === service.Id)
                .map((price: Price) =>
                    apiClient.post('/services/prices', {
                        ...price,
                        Id: undefined,
                        ServiceId: newServiceId,
                    })
                )
        );

        yield put(push(`/services/${newServiceId}`));
        yield put(actions.COPY_SERVICE.success(serviceResponse.data));
    } catch (error) {
        yield put(actions.COPY_SERVICE.failure(error));
    }
}

export function* scheduleTypes({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services/scheduletypes');

        yield put({
            type: actions.FETCH_SCHEDULE_TYPES.SUCCESS,
            payload: { Results: response.data },
        });
    } catch (error) {
        yield put(actions.FETCH_SCHEDULE_TYPES.failure(error));
    }
}

export function* serviceGroups({ payload }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get('/services/groups');

        yield put({
            type: actions.FETCH_SERVICE_GROUPS.SUCCESS,
            payload: { Results: response.data },
        });
    } catch (error) {
        yield put(actions.FETCH_SERVICE_GROUPS.failure(error));
    }
}
