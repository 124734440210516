import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid, Form as UIForm, Header, Icon, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { LocalizeContextProps, TranslateFunction, withLocalize } from 'react-localize-redux';

import { FieldLabel } from '@/components/Input';
import CustomFields from '@/components/CustomFields';
import Form, { FormProps } from '@/components/Form';
import Field from '@/components/Field';
import Truncate from '@/components/Truncate';
import { FolderTypes } from '@/utils/constants';
import * as actions from '@/store/actions';
import { ListState, Service, ServiceGroup, CustomField } from '@/types';

import { EditModeToggle, CircleImage, SliderWrapper } from './styled';
import { Flex } from 'grid-styled';
import { range } from 'lodash';
import { getDurationComputed, convertToFormatOptions, formatMinutesToWords, getDurationBaseUnit, formatTimeToWords } from '@/utils/datetime';
import Option from './Option'

import { FlexibleSlider } from './FlexibleSlider';


import { compose } from 'redux';
import { connect } from 'react-redux';
import { operations } from '@/apitypes';


const ServiceName = styled.p`
    font-size: 1.6em;
`;
const ServiceGroupEl = styled.div`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.gray};
`;
const Description = styled.p`
    color: ${({ theme }) => theme.secondary};
`;

const CozyInputFieldVariantContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 767px) {
            flex-grow: 1;
    }
    & .input {
        min-width: 100%;
        @media only screen and (min-width: 768px) {
            min-width: 320px;
        }
    }
`

const SmallNumberInput = styled.div`
    .ui.action input[type="number"] {
        max-width: 96px;
        width: auto!important;
    }
`;

const { Row, Column } = Grid;

interface OuterProps {
    editing: boolean;
    showHeader?: boolean;
    service: Service;
    serviceDurationTypes: ListState<Array<operations["DurationTypeQuerydurationtypes_Get"]['responses']['200']['schema']>>
    onToggleEdit: () => void;
    serviceGroups: ListState<ServiceGroup>;
    onSubmitSuccess: (result: any) => void;
}

interface Props extends OuterProps, LocalizeContextProps, RouteComponentProps<{ id: string }> {

    
}

interface State {
    groupNameOpen: boolean;
}

class ServiceInformationForm extends Component<Props> {
    state: Readonly<State> = {
        groupNameOpen: false
    };

    render() {
        const { editing, service, translate, showHeader = true } = this.props;

        return (
            <Grid>
                {showHeader ? <Column computer={4} mobile={16}>
                    {!editing && <CircleImage src={service.ImageUrl || 'assets/image.png'} />}
                    {editing ? (
                        <Header as="h3" content={translate('service.generalInformation')} />
                    ): null}
                </Column> : null}
                <Column computer={showHeader ? 12 : 16} mobile={16}>
                    {editing ? this.renderForm() : this.renderView()}
                </Column>
            </Grid>
        );
    }

    renderView = () => {
        const { service, onToggleEdit, translate } = this.props;

        if (!service) return null;

        let durationBaseUnit = 'minutes';
        let formattedDuration = service.Duration;

        if (service.Duration % 1440 === 0) {
            durationBaseUnit = 'days';
            formattedDuration = service.Duration / 1440;
        } else if (service.Duration % 60 === 0) {
            durationBaseUnit = 'hours';
            formattedDuration = service.Duration / 60;
        }

        return (
            <Grid>
                <Row>
                    <Column width={16}>
                        <ServiceName>
                            {service.Name}
                            <EditModeToggle onClick={onToggleEdit}>
                                {translate('common.edit')}
                            </EditModeToggle>
                            <ServiceGroupEl>{service.Group}</ServiceGroupEl>
                        </ServiceName>
                        <Description>
                            <Truncate>{service.Description}</Truncate>
                        </Description>
                        <Icon name="clock outline" color="grey" /> {formattedDuration}{' '}
                        {translate(`common.${durationBaseUnit}`)}
                    </Column>
                </Row>
                {service.CustomFieldValues.map(
                    (fieldValue) =>
                        fieldValue.Value && (
                            <Row key={fieldValue.Id}>
                                <Column width={4}>{fieldValue.Name}</Column>{' '}
                                <Column width={12}>
                                    <strong>{fieldValue.Value}</strong>
                                </Column>
                            </Row>
                        )
                )}
            </Grid>
        );
    };

    renderForm = () => {
        const {
            onToggleEdit,
            translate,
            serviceGroups,
            match,
            service,
            onSubmitSuccess,
            serviceDurationTypes
        } = this.props;
        const labelColumnWidth = 4;
        const inputColumnWidth = 10;

        let _DurationBaseUnit = getDurationBaseUnit(service.Duration);
        let _Duration = getDurationComputed(service.Duration, _DurationBaseUnit);

        const serviceDurationTypesOptions = serviceDurationTypes.data.map(sd => ({
            value: sd.Id,
            text: Option(sd.Name!, sd.Description!)
            // text: sd.Name
        }))

        const initialValues: any = {
            // @ts-ignore
            CustomFields: [],
            ...service,
            _DurationBaseUnit,
            _Duration,
            _MinDuration: getDurationComputed(service.MinDuration || service.Duration, _DurationBaseUnit),
            _MaxDuration: getDurationComputed(service.MaxDuration || service.Duration * 3, _DurationBaseUnit),
            _DurationInterval: getDurationComputed(service.DurationInterval || service.Duration, _DurationBaseUnit),
            _FlexibleHours: service.DurationTypeId || 1
        };

        initialValues.CustomFields = initialValues.CustomFields.map(
            (field: CustomField, index: number) => {
                // Populate custom field values if it's not a new service
                return match.params.id
                    ? { ...service.CustomFieldValues[index], meta: field }
                    : { meta: field };
            }
        );

        return (
            <Form
                name="serviceGeneral"
                initialValues={initialValues}
                action={match.params.id ? actions.EDIT_SERVICE : actions.CREATE_SERVICE}
                attachActionBar={false}
                enableReinitialize={false}
                basic
                onCancel={onToggleEdit}
                customOnSubmitSuccess={onSubmitSuccess}
                confirmOnLeave
            >
                {({ formValues, change }: FormProps<any>) => {
                    const sliderMinDuration = parseInt(formValues._MinDuration);
                    const sliderDurationInterval = parseInt(formValues._DurationInterval)
                    const serviceDuration = parseInt(formValues.Duration);     

                    const sliderMaxDuration = (parseInt(formValues._MaxDuration) - sliderMinDuration) % sliderDurationInterval === 0
                        ? parseInt(formValues._MaxDuration)
                        : parseInt(formValues._MaxDuration) - (
                            (parseInt(formValues._MaxDuration) - sliderMinDuration) % sliderDurationInterval
                        );


                    let _marks =
                        !isNaN(sliderMinDuration) &&
                        !isNaN(sliderMaxDuration) &&
                        !isNaN(sliderDurationInterval)
                            ? range(
                                  sliderMinDuration,
                                  sliderMaxDuration + 1,
                                  sliderDurationInterval
                              )
                            : [];

                    // TODO: convert curr to minutes
                    let marks = _marks
                        .reduce((acc, curr) => {
                            acc[curr] = formatMinutesToWords(curr, {
                                abbreaviated: _marks.length >= 7 ? true : false,
                                unit: convertToFormatOptions(formValues._DurationBaseUnit)
                            });

                            return acc;
                        }, {} as {
                            [key in string]: any;
                        });

                    let defaultSliderValue = 0;

                    if(
                        isNaN(sliderMaxDuration) &&
                        isNaN(sliderDurationInterval) &&
                        !isNaN(serviceDuration)
                    ) {
                        defaultSliderValue = serviceDuration;
                    } else if(
                        !isNaN(sliderMaxDuration) &&
                        !isNaN(sliderDurationInterval) &&
                        !isNaN(serviceDuration)
                    ) {
                        defaultSliderValue = serviceDuration <
                        parseInt(formValues._MaxDuration)
                            ? serviceDuration
                            : sliderDurationInterval;
                    }
                    
                    const filteredServiceGroups = serviceGroups.data
                        .filter(
                            (group) =>
                                !formValues.Group ||
                                (group &&
                                    formValues.Group &&
                                    group.Name.toLowerCase().includes(
                                        formValues.Group.toLowerCase()
                                    ))
                        );
                    
                        const dropdownMarks = _marks
                        .map((mark) => ({
                            value: mark,
                            text: formatTimeToWords(mark, convertToFormatOptions(formValues._DurationBaseUnit) as any || "minute", translate)
                        }));

                    return (
                        <>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="ImageUrl"
                                    text={translate('service.ImageUrl.text')}
                                    help={translate('service.ImageUrl.help')}
                                    width={labelColumnWidth}
                                />
                                <Field
                                    name="ImageUrl"
                                    label={null}
                                    type="image"
                                    folderType={FolderTypes.SERVICE}
                                />
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="Name"
                                    text={translate('service.Name.text')}
                                    help={translate('service.Name.help')}
                                    width={labelColumnWidth}
                                />
                                <Field name="Name" type="text" width={inputColumnWidth} />
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="Description"
                                    text={translate('service.Description.text')}
                                    help={translate('service.Description.help')}
                                    width={labelColumnWidth}
                                />
                                <Field
                                    name="Description"
                                    type="textarea"
                                    width={inputColumnWidth}
                                />
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="CustomFields"
                                    // text={translate('service.CustomFields.text')}
                                    // help={translate('service.CustomFields.help')}
                                    width={labelColumnWidth}
                                />
                                <div>
                                    <CustomFields name="CustomFields" />
                                </div>
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="_FlexibleHours"
                                    text={translate('service.FlexibleHours.text')}
                                    width={labelColumnWidth}
                                />
                                <Field
                                    data-cy="flexible-hours-select"
                                    name="_FlexibleHours"
                                    type="select"
                                    inline
                                    width={inputColumnWidth}
                                    options={serviceDurationTypesOptions}
                                />
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="Duration"
                                    text={translate('service.Duration.text')}
                                    help={translate('service.Duration.help')}
                                    width={labelColumnWidth}
                                />
                                {formValues._FlexibleHours !== 1 ? (
                                    <Flex flexDirection={'column'} style={{ flex: 1 }}>
                                        <Flex flexDirection={'column'} style={{ gap: '8px' }}>
                                            <FieldLabel
                                                htmlFor="_Duration"
                                                text={translate('service.DefaultDuration.text')}
                                            />
                                            <SmallNumberInput>
                                                <Field
                                                    icon="time"
                                                    iconPosition="left"
                                                    name="_Duration"
                                                    type="number"
                                                    min={1}
                                                    style={{ marginBottom: '8px' }}
                                                    action={
                                                        <Field
                                                            data-cy="duration-base-unit-dropdown"
                                                            name="_DurationBaseUnit"
                                                            type="dropdown"
                                                            withoutWrapper
                                                            button
                                                            compact
                                                            options={[
                                                                {
                                                                    value: 'minutes',
                                                                    text: translate(
                                                                        'common.minutes'
                                                                    ),
                                                                },
                                                                {
                                                                    value: 'hours',
                                                                    text: translate('common.hours'),
                                                                },
                                                                {
                                                                    value: 'days',
                                                                    text: translate('common.days'),
                                                                },
                                                            ]}
                                                        />
                                                    }
                                                    inline
                                                    width={inputColumnWidth}
                                                />
                                            </SmallNumberInput>
                                        </Flex>
                                        <Flex style={{ flexWrap: 'wrap', flex: 1 }}>
                                            <ServiceDurationFormPartial translate={translate} />
                                        </Flex>
                                        {Object.keys(marks).length > 0 &&
                                        formValues._FlexibleHours === 2 ? (
                                            <>
                                                <p>
                                                    {translate('service.previewFlexibleHours')}
                                                    {_DurationBaseUnit ? (
                                                        <span>
                                                            {' '}
                                                            (
                                                            {translate(
                                                                'service.previewFlexibleHours.shownIn'
                                                            )}{' '}
                                                            {convertToFormatOptions(
                                                                formValues._DurationBaseUnit
                                                            ) === 'day'
                                                                ? translate('common.days')
                                                                : convertToFormatOptions(
                                                                      formValues._DurationBaseUnit
                                                                  ) === 'hour'
                                                                ? translate('common.hours')
                                                                : convertToFormatOptions(
                                                                      formValues._DurationBaseUnit
                                                                  ) === 'minute'
                                                                ? translate('common.minutes')
                                                                : null}
                                                            )
                                                        </span>
                                                    ) : null}
                                                </p>
                                                <FlexibleSlider
                                                    maxDuration={sliderMaxDuration}
                                                    minDuration={parseInt(formValues._MinDuration)}
                                                    durationInterval={parseInt(formValues._DurationInterval)}
                                                    defaultValue={defaultSliderValue}
                                                    marks={marks}
                                                    unit={_DurationBaseUnit}
                                                />
                                            </>
                                        ) : null}
                                        {Object.keys(marks).length > 0 &&
                                        formValues._FlexibleHours === 3 ? (
                                            <UIForm.Group>
                                                <SliderWrapper>
                                                    <p>
                                                        {translate('service.previewFlexibleHours')}
                                                        {_DurationBaseUnit ? (
                                                            <span>
                                                                {' '}
                                                                (
                                                                {translate(
                                                                    'service.previewFlexibleHours.shownIn'
                                                                )}{' '}
                                                                {convertToFormatOptions(
                                                                    formValues._DurationBaseUnit
                                                                ) === 'day'
                                                                    ? translate('common.days')
                                                                    : convertToFormatOptions(
                                                                          formValues._DurationBaseUnit
                                                                      ) === 'hour'
                                                                    ? translate('common.hours')
                                                                    : convertToFormatOptions(
                                                                          formValues._DurationBaseUnit
                                                                      ) === 'minute'
                                                                    ? translate('common.minutes')
                                                                    : null}
                                                                )
                                                            </span>
                                                        ) : null}
                                                    </p>
                                                    <select
                                                        data-cy="flexible-hours-dropdown"
                                                        className="custom-select"
                                                    >
                                                        {dropdownMarks.map(
                                                            (mark: any, i: number) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        value={mark.value}
                                                                    >
                                                                        {mark.text}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </select>
                                                </SliderWrapper>
                                            </UIForm.Group>
                                        ) : null}
                                    </Flex>
                                ) : (
                                    <SmallNumberInput>
                                        <Field
                                            icon="time"
                                            iconPosition="left"
                                            name="_Duration"
                                            type="number"
                                            min={1}
                                            action={
                                                <Field
                                                    name="_DurationBaseUnit"
                                                    type="dropdown"
                                                    withoutWrapper
                                                    button
                                                    compact
                                                    options={[
                                                        {
                                                            value: 'minutes',
                                                            text: translate('common.minutes'),
                                                        },
                                                        {
                                                            value: 'hours',
                                                            text: translate('common.hours'),
                                                        },
                                                        {
                                                            value: 'days',
                                                            text: translate('common.days'),
                                                        },
                                                    ]}
                                                />
                                            }
                                            inline
                                            width={inputColumnWidth}
                                        />
                                    </SmallNumberInput>
                                )}
                            </UIForm.Group>

                            <UIForm.Group inline>
                                <FieldLabel width={labelColumnWidth} />
                                {/* {formValues._DurationBaseUnit === 'minutes' && (
                                    <UIForm.Field style={{ marginBottom: '1em' }}>
                                        <Icon name="arrow right" />
                                        <strong>{lengthString}</strong>
                                    </UIForm.Field>
                                )} */}
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="SortOrder"
                                    text={translate('service.SortOrder.text')}
                                    help={translate('service.SortOrder.help')}
                                    width={labelColumnWidth}
                                />

                                <Field addon="icon-pin" name="SortOrder" type="number" />
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="Group"
                                    text={translate('service.Group.existing.text')}
                                    help={translate('service.Group.existing.help')}
                                    width={labelColumnWidth}
                                />
                                <CozyInputFieldVariantContainer>
                                    <Field
                                        data-testid="group-search"
                                        autoComplete="off"
                                        name="Group"
                                        type="input"
                                        icon="dropdown"
                                        iconPosition="right"
                                        clearable
                                        onFocus={() => {
                                            this.setState({ groupNameOpen: true });
                                        }}
                                        onBlur={(ev: any) => {
                                            setTimeout(() => {
                                                this.setState({ groupNameOpen: false });
                                            }, 200);
                                        }}
                                    />
                                    <Dropdown
                                        data-testid="group-select-dropdown"
                                        style={{ width: 'calc(100% - 1em)' }}
                                        scrolling
                                        icon={null}
                                        open={this.state.groupNameOpen}
                                        onClick={(ev) => {
                                            this.setState({ groupNameOpen: false });
                                        }}
                                    >
                                        <Dropdown.Menu
                                            style={{
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom:
                                                    filteredServiceGroups.length < 5
                                                        ? `-${
                                                              filteredServiceGroups.length * 36 - 16
                                                          }px`
                                                        : '-200px',
                                            }}
                                        >
                                            {filteredServiceGroups.map((group) => {
                                                return (
                                                    <Dropdown.Item
                                                        key={`${group.Name}`}
                                                        value={group.Name}
                                                        text={group.Name}
                                                        onClick={(e) => {
                                                            change('Group', group.Name);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </CozyInputFieldVariantContainer>
                            </UIForm.Group>
                            <UIForm.Group inline>
                                <FieldLabel
                                    htmlFor="Group"
                                    label={translate('service.Group.new.text')}
                                    help={translate('service.Group.existing.help')}
                                    width={labelColumnWidth}
                                />
                                <CozyInputFieldVariantContainer>
                                    <Field addon="icon-pin" name="newGroup" type="text" />
                                </CozyInputFieldVariantContainer>
                            </UIForm.Group>
                        </>
                    );
                }}
            </Form>
        );
    };
}

// export default withRouter(withLocalize(ServiceInformationForm));
export default compose(
    withRouter,
    withLocalize,
    connect()
)(ServiceInformationForm) as React.ComponentType<OuterProps>;


export const ServiceDurationFormPartial: React.FC<{ translate: TranslateFunction }> = ({ translate }) => {
    return (
        <>
            <UIForm.Group inline style={{ alignItems: 'center' }}>
                <label>{translate('service.MinDuration.text')}</label>
                <SmallNumberInput>
                    <Field
                        icon="time"
                        iconPosition="left"
                        name="_MinDuration"
                        type="number"
                        action={
                            <Field
                                name="_DurationBaseUnit"
                                type="dropdown"
                                disabled
                                withoutWrapper
                                button
                                compact
                                options={[
                                    {
                                        value: 'minutes',
                                        text: translate('common.minutes'),
                                    },
                                    {
                                        value: 'hours',
                                        text: translate('common.hours'),
                                    },
                                    {
                                        value: 'days',
                                        text: translate('common.days'),
                                    },
                                ]}
                            />
                        }
                        inline
                    />
                </SmallNumberInput>
            </UIForm.Group>
            <UIForm.Group inline style={{ alignItems: 'center' }} widths={'11'}>
                <label>{translate('service.MaxDuration.text')}</label>
                <SmallNumberInput>
                    <Field
                        icon="time"
                        iconPosition="left"
                        name="_MaxDuration"
                        type="number"
                        action={
                            <Field
                                name="_DurationBaseUnit"
                                type="dropdown"
                                disabled
                                withoutWrapper
                                button
                                compact
                                options={[
                                    {
                                        value: 'minutes',
                                        text: translate('common.minutes'),
                                    },
                                    {
                                        value: 'hours',
                                        text: translate('common.hours'),
                                    },
                                    {
                                        value: 'days',
                                        text: translate('common.days'),
                                    },
                                ]}
                            />
                        }
                        inline
                    />
                </SmallNumberInput>
            </UIForm.Group>
            <UIForm.Group inline style={{ alignItems: 'center' }}>
                <label>{translate('service.DurationInterval.text')}</label>
                <SmallNumberInput>
                    <Field
                        icon="time"
                        iconPosition="left"
                        name="_DurationInterval"
                        type="number"
                        action={
                            <Field
                                name="_DurationBaseUnit"
                                type="dropdown"
                                disabled
                                withoutWrapper
                                button
                                compact
                                options={[
                                    {
                                        value: 'minutes',
                                        text: translate('common.minutes'),
                                    },
                                    {
                                        value: 'hours',
                                        text: translate('common.hours'),
                                    },
                                    {
                                        value: 'days',
                                        text: translate('common.days'),
                                    },
                                ]}
                            />
                        }
                        inline
                    />
                </SmallNumberInput>
            </UIForm.Group>
        </>
    );
};
